var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-async-select", {
    staticClass: "advertiser-filter",
    attrs: {
      id: "advertiser-select",
      name: "select-advertiser",
      "add-hex-color": "orange",
      value: _vm.value,
      label: _vm.label,
      "class-input": "pt-0 shadow-none rounded-none multiselect",
      "text-error": _vm.textError,
      error: _vm.error,
      "items-per-page": 100,
      "disable-selected-options": _vm.disableSelectedOptions,
      required: _vm.required,
      disabled: _vm.disabled,
      service: _vm.apiService,
    },
    on: { change: _vm.emitSelection },
    scopedSlots: _vm._u([
      {
        key: "option",
        fn: function ({ option }) {
          return [
            _c(
              "div",
              { staticClass: "flex flex-row items-center -my-1" },
              [
                _c(
                  "span",
                  {
                    staticClass: "pr-4 truncate max-w-2/4",
                    attrs: { title: option.name },
                  },
                  [_vm._v(_vm._s(option.name))]
                ),
                _c("platform-pill", {
                  attrs: {
                    platform: option.platform,
                    "allow-navigation": false,
                  },
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }